import type { QuestWithMeta } from "@fscrypto/domain/earn";
import { Text } from "@fscrypto/flip-kit/text";
import Decimal from "decimal.js";
import { Progress } from "~/components/ui/progress";

// Formats the remaining number based on the total number of rewards.
function formatRemainingNumber(remaining: number, total: number): string {
  if (remaining <= 0) return "0";
  if (total <= 1) return remaining.toFixed(2);
  if (total <= 10) return remaining.toFixed(1);
  return Math.floor(remaining).toString();
}

export const getRewardsRemaining = (quest: QuestWithMeta) => {
  const questSpend = quest.rewardSpecs.reduce((acc, r) => r.tokenSpent.add(acc), new Decimal(0));
  const questBudget = quest.rewardSpecs.reduce((acc, r) => r.tokenBudget.add(acc), new Decimal(0));

  const total = questBudget.toNumber();
  let remaining = questBudget.minus(questSpend).toNumber();
  if (remaining < 0) remaining = 0;

  return { remaining, total };
};

export const ProgressRewardsRemaining = ({
  remaining,
  total,
  theme,
}: {
  remaining: number;
  total: number;
  theme?: "default" | "dark";
}) => {
  const remainingFormatted = formatRemainingNumber(remaining, total);

  return (
    <>
      <div className="mb-2">
        <Text variant="body" className="mr-1">
          {remainingFormatted}
        </Text>
        <Text variant="caption" className="text-text-tertiary">
          / {total}
        </Text>
      </div>
      <Progress variant={theme} value={(remaining / total) * 100} />
    </>
  );
};
