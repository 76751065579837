import * as ProgressPrimitive from "@radix-ui/react-progress";
import { cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "~/lib/utils";

const indicatorVariants = cva("h-full w-full flex-1 bg-gradient-to-r transition-all", {
  variants: {
    color: {
      abundant: "from-brand-3 to-brand-1", //blue
      scarce: "from-brand-4 to to-brand-2", //red
    },
  },
});

const variants = cva("relative h-2 w-full overflow-hidden rounded-full ", {
  variants: {
    color: {
      default: "bg-bg-tertiary/20",
      dark: "bg-primary/20",
    },
  },
  defaultVariants: {
    color: "default",
  },
});

const Progress = React.forwardRef<
  React.ElementRef<typeof ProgressPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ProgressPrimitive.Root> & {
    variant?: "default" | "dark";
  }
>(({ className, value, variant, ...props }, ref) => (
  <ProgressPrimitive.Root ref={ref} className={cn(variants({ color: variant }), className)} {...props}>
    <ProgressPrimitive.Indicator
      className={cn(indicatorVariants({ color: value && value < 50 ? "scarce" : "abundant" }))}
      style={{ transform: `translateX(-${100 - (value || 0)}%)` }}
    />
  </ProgressPrimitive.Root>
));
Progress.displayName = ProgressPrimitive.Root.displayName;

export { Progress };
