import { z } from "zod";

export const apiKeyIdSchema = z.string().uuid();

export const apiKeySchema = z.object({
  id: apiKeyIdSchema,
  key: z.string().min(1),
  isActive: z.boolean(),
  userId: z.string().uuid(),
  hosts: z.array(z.string()).optional(),
  dailyLimit: z.number().nonnegative(),
  burstLimit: z.number().nonnegative(),
  createdAt: z.date(),
  updatedAt: z.date(),
  createdById: z.string().uuid(),
  updatedById: z.string().uuid(),
});

export const createApiKeySchema = z.object({
  key: z.string().min(1),
  isActive: z.boolean().default(true),
  userId: z.string().uuid(),
  hosts: z.array(z.string()).optional(),
  dailyLimit: z.number().nonnegative().default(0),
  burstLimit: z.number().nonnegative().default(0),
  createdById: z.string().uuid(),
});

export const updateApiKeySchema = z.object({
  id: apiKeyIdSchema,
  key: z.string().min(1).optional(),
  isActive: z.boolean().optional(),
  hosts: z.array(z.string()).optional(),
  dailyLimit: z.number().nonnegative().optional(),
  burstLimit: z.number().nonnegative().optional(),
  updatedById: z.string().uuid(),
});

export type ApiKeyId = z.infer<typeof apiKeyIdSchema>;
export type ApiKey = z.infer<typeof apiKeySchema>;
export type CreateApiKey = z.infer<typeof createApiKeySchema>;
export type UpdateApiKey = z.infer<typeof updateApiKeySchema>;
export enum ValidateResult {
  Valid = "Valid",
  MissingApiKey = "Missing API key",
  InvalidApiKey = "Invalid API key",
  ApiKeyRevoked = "API key has been revoked",
}
