import { z } from "zod";

// Base assertion types
export const assertionTypeSchema = z.enum(["contains", "equals", "rubric"]);
export type AssertionType = z.infer<typeof assertionTypeSchema>;

const assertionConfigSchema = z.object({
  type: assertionTypeSchema,
  value: z.string().optional(),
  threshold: z.number().optional(),
  criteria: z.array(z.string()).optional(),
  metadata: z.record(z.unknown()).optional(),
});

export type AssertionConfig = z.infer<typeof assertionConfigSchema>;

// Interface for test case
export interface TestCase {
  input: string;
  expectedOutput?: string;
  metadata?: Record<string, unknown>;
}

export const testConfigSchema = z.object({
  input: z.string(),
  expectedOutput: z.string().optional(),
  assertions: z.array(assertionConfigSchema),
  metadata: z.record(z.unknown()).optional(),
});

export type TestConfig = z.infer<typeof testConfigSchema>;

export const agentTestConfigSchema = z.object({
  agentId: z.string(),
  testConfigs: z.array(testConfigSchema),
});

export type AgentTestConfig = z.infer<typeof agentTestConfigSchema>;

export const testSuiteMetadataSchema = z
  .object({
    agentId: z.string().optional(),
    agentVersion: z.number().optional(),
    agentModel: z.string().optional(),
    agentProvider: z.string().optional(),
  })
  .and(z.record(z.unknown()));

export type TestSuiteMetadata = z.infer<typeof testSuiteMetadataSchema>;

export const testSuiteSchema = z.object({
  systemPrompt: z.string(),
  provider: z.string(),
  tests: z.array(testConfigSchema),
  metadata: testSuiteMetadataSchema.optional(),
});

export type TestSuite = z.infer<typeof testSuiteSchema>;

export const criterionResultSchema = z.object({
  criterion: z.string(),
  pass: z.boolean(),
  score: z.number(),
  reason: z.string(),
});

export type CriterionResult = z.infer<typeof criterionResultSchema>;

export const assertionResultSchema = z.object({
  passed: z.boolean(),
  message: z.string(),
  score: z.number(),
  metadata: z.record(z.unknown()).optional(),
  type: assertionTypeSchema,
  criteriaResults: z.array(criterionResultSchema).optional(),
});

export type AssertionResult = z.infer<typeof assertionResultSchema>;

export const testCaseResultSchema = z.object({
  input: z.string(),
  output: z.string(),
  assertions: z.array(assertionResultSchema),
  passed: z.boolean(),
});

export type TestCaseResult = z.infer<typeof testCaseResultSchema>;

export const aiAgentEvaluationSchema = z.object({
  testCases: z.array(testCaseResultSchema),
  summary: z.object({
    total: z.number(),
    passed: z.number(),
    failed: z.number(),
  }),
  metadata: testSuiteMetadataSchema.optional(),
  evaluatedAt: z.string().optional(),
});

export type AiAgentEvaluation = z.infer<typeof aiAgentEvaluationSchema>;

export const gradingResultSchema = z.object({
  pass: z.boolean(),
  score: z.number(),
  reason: z.string(),
  criteriaResults: z.array(criterionResultSchema).optional(),
});

export type GradingResult = z.infer<typeof gradingResultSchema>;

export const createAiEvaluationSchema = z.object({
  agentId: z.string(),
  result: aiAgentEvaluationSchema,
});

export type CreateAiEvaluation = z.infer<typeof createAiEvaluationSchema>;
