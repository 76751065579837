import { z } from "zod";

export const adhocPermissionsList = ["ADHOC_PAYMENT_APPROVE", "ADHOC_PAYMENT_SUBMIT"] as const;

export const aiAgentPermissionsList = ["AI_AGENT_ADMINISTER", "AI_AGENT_EXECUTE"] as const;

export const budgetingPermissionsList = [
  "BUDGET_QUARTERLY_PLAN_APPROVE",
  "BUDGET_MONTHLY_PLAN_APPROVE",
  "BUDGET_QUARTERLY_PLAN_EDIT",
  "BUDGET_MONTHLY_PLAN_EDIT",
  "BUDGET_SUDO",
  "BUDGET_APPROVE_NEW_SPEND",
] as const;

export const earnPermissionsList = ["QUEST_REWARDS_EDIT", "QUEST_PREVIEW"] as const;

export const rbacPermissionsList = ["USER_ADMINISTER"] as const;

export const adhocPermissionsSchema = z.enum(adhocPermissionsList);
export const budgetingPermissionsSchema = z.enum(budgetingPermissionsList);
export const earnPermissionsSchema = z.enum(earnPermissionsList);

export const permission = z.enum([
  ...aiAgentPermissionsList,
  ...adhocPermissionsList,
  ...budgetingPermissionsList,
  ...earnPermissionsList,
  ...rbacPermissionsList,
]);

// When adding new roles, keep in mind the guidance in ./PERMISSIONS.md
// Also make sure to update the seed file with the new roles at packages/database/prisma/seed.ts
export const rolePermissionMap: Record<string, Set<Permission>> = {
  aiAutomationUser: new Set([permission.Enum.AI_AGENT_EXECUTE]),
  aiAutomationAdmin: new Set([permission.Enum.AI_AGENT_ADMINISTER, permission.Enum.AI_AGENT_EXECUTE]),
  financeOperator: new Set([
    adhocPermissionsSchema.Enum.ADHOC_PAYMENT_SUBMIT,
    adhocPermissionsSchema.Enum.ADHOC_PAYMENT_APPROVE,
    budgetingPermissionsSchema.Enum.BUDGET_APPROVE_NEW_SPEND,
  ]),
  growthServicesOperator: new Set([
    adhocPermissionsSchema.Enum.ADHOC_PAYMENT_SUBMIT,
    budgetingPermissionsSchema.Enum.BUDGET_MONTHLY_PLAN_EDIT,
    earnPermissionsSchema.Enum.QUEST_PREVIEW,
  ]),
  growthServicesLead: new Set([
    adhocPermissionsSchema.Enum.ADHOC_PAYMENT_SUBMIT,
    budgetingPermissionsSchema.Enum.BUDGET_MONTHLY_PLAN_EDIT,
    budgetingPermissionsSchema.Enum.BUDGET_QUARTERLY_PLAN_EDIT,
    budgetingPermissionsSchema.Enum.BUDGET_QUARTERLY_PLAN_APPROVE,
    budgetingPermissionsSchema.Enum.BUDGET_SUDO,
    earnPermissionsSchema.Enum.QUEST_PREVIEW,
  ]),
  questRewardsEditor: new Set([permission.Enum.QUEST_REWARDS_EDIT, permission.Enum.QUEST_PREVIEW]),
  userAdmin: new Set([permission.Enum.USER_ADMINISTER]),
};

export const roleChangeResultSchema = z.object({
  keptRoles: z.set(z.string()),
  addedRoles: z.set(z.string()),
  removedRoles: z.set(z.string()),
  keptPermissions: z.set(permission),
  addedPermissions: z.set(permission),
  removedPermissions: z.set(permission),
});

export type Permission = z.infer<typeof permission>;
export type RoleChangeResult = z.infer<typeof roleChangeResultSchema>;
